<template>
  <v-container>
    <v-row>
      <v-col cols="auto" style="min-height: 64px;">
        <v-btn
          v-if="isProjectOwner || roleDashboard.create"
          class="btn-add-panel"
          dark
          color="primary"
          @click="onCreatePanel"
        >
          {{ $t('page.panels.add_panel_title') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="isLoading"
      indeterminate
      absolute
      color="primary"
    />

    <IssueCard
      v-else-if="hasNoData"
      class="mt-13"
      :message="issueCardMessage"
      :error="error"
    />

    <v-row
      v-if="grafanaDatasourceId"
      v-show="!isLoading && !hasNoData"
      class="panels-row"
      no-gutters
    >
      <v-col
        v-for="panel in panels"
        :key="panel.id"
        class="panels-col"
        cols="auto"
      >
        <PanelCard
          :dashboard="dashboard"
          :panel="panel"
          @delete="onDeletePanel"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import PanelCard from '@/components/PanelCard'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Panels',

  components: {
    IssueCard,
    PanelCard
  },

  props: {
    isLoadingProject: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isDeleting: false,
    error: false
  }),

  computed: {
    ...mapState({
      grafanaDatasourceId: state => state.panels.grafanaDatasourceId,
      isLoadingDatasourceId: function(state) {
        return state.panels.isLoadingDatasourceId === this.project.grafanaId
      },
      isLoadingDashboards: state => state.dashboards.isLoadingDashboards
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getDashboardByRoute: 'dashboards/getDashboardByRoute',
      getPanelsByRoute: 'dashboards/getPanelsByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId'
    }),

    isLoading() {
      return (
        this.isLoadingProject ||
        this.isLoadingDashboards ||
        this.isDeleting ||
        this.isLoadingDatasourceId
      )
    },
    hasNoData() {
      return (
        this.error || !Array.isArray(this.panels) || this.panels.length === 0
      )
    },
    issueCardMessage() {
      // fetch api error
      if (this.error) return this.$t('api_errors.data_error_refresh_page')

      // no data text
      // can create panel
      if (this.roleDashboard.create) return this.$t('page.panels.no_data_and_create_panel')

      // cannot create panel
      return this.$t('page.panels.no_data')
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    isProjectOwner() {
      return this.project?.isOwner
    },
    dashboard() {
      return this.getDashboardByRoute(this.$route)
    },
    panels() {
      return this.getPanelsByRoute(this.$route).sort((a, b) => b.id - a.id)
    },
    roleDashboard() {
      if (!this.dashboard) return {}

      return (
        this.getRoleByResourceId(this.project, this.dashboard.resourceId) || {}
      )
    },
    toPanelAdder() {
      return {
        name: 'PanelAdder',
        params: this.$route.params
      }
    }
  },

  // 使用keep alive減少panel snapshotUrl獲取次數
  activated() {
    this.$store.dispatch('snackbar/showMessage', {
      snackText: this.$t('loading_panels_please_wait'),
      snackColor: 'grey',
      snackTimeout: 10000
    })

    if (!this.dashboard?.resourceId) {
      this.fetchDashboard()
    }

    if (this.grafanaDatasourceId == null) {
      this.fetchDatasourceId()
    }
  },

  methods: {
    fetchDashboard() {
      if (this.isLoadingDashboards) return

      this.error = false
      return this.$store
        .dispatch('dashboards/fetchDashboard', {
          projectId: this.$route.params.projectId,
          dashboardId: this.$route.params.dashboardId
        })
        .catch(() => {
          this.error = true
        })
    },
    fetchDatasourceId() {
      if (!this.project?.grafanaId || this.isLoadingDatasourceId) {
        return Promise.resolve()
      }

      return this.$store.dispatch('panels/fetchGrafanaDatasourceId', {
        grafanaId: this.project.grafanaId,
        projectId: (!this.project.isOwner && this.project.uuid) || null
      })
    },
    onDeletePanel(panel) {
      if (!panel) return

      if (!this.isProjectOwner && !this.roleDashboard.delete) return

      this.isDeleting = true
      return this.$store
        .dispatch('panels/deletePanel', {
          dashboard: this.dashboard,
          panel,
          projectId: this.$route.params.projectId
        })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_deleted')
          })

          return this.fetchDashboard()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
    onCreatePanel() {
      if (!this.isProjectOwner && !this.roleDashboard.create) return

      this.$router.push({
        name: 'PanelAdder',
        params: this.$route.params,
        query: this.$route.query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default.btn-add-panel {
  padding: 20px 2.25rem;
  font-size: 1.25rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

$col: 1;
$col-xl: 2;
$row-gap: 32px;

.panels-row {
  gap: $row-gap;

  .panels-col {
    width: calc(100% / #{$col} - #{$row-gap} * #{$col - 1});
    height: 226px;

    @media #{map-get($display-breakpoints, "lg-and-up")} {
      width: calc(100% / #{$col-xl} - #{$row-gap} * #{$col-xl - 1});
    }
  }
}
</style>
